@import '../styles/mixins';
@import '../styles/variables';
@import '../styles/type-guide';

footer#footer {
	padding:0px;
	background:#e6e9f0;

	@include media-query($mobile){
		padding:0px;
	}

	ul {
		list-style:none;
		margin:0;
		padding:0;

		padding:30px;
		background:#e6e9f0;
		text-align:center;

		@include media-query($mobile){
			padding:20px;
		}

		li {
			margin:0;
			padding:0;
			display:inline-block;
			font-size:30px;

			@include media-query($mobile){
				font-size:20px;
			}

			 &:after {
			 	content: '\00a0/\00a0';
			 }

			 &:last-of-type:after {
			 	content:'';
			 }

			a {
				color:$color-black;
				text-decoration:none;
				opacity:1;
				@include transition(opacity 300ms linear);

				&:hover {
					opacity:0.5;
				}
			}
		}
	}

	.social_icons {
		background:$color-black;
	}
}

.social_icons {
	padding:30px;
	//background:$color-black;
	color:$color-white;
	display:grid;
	grid-template-columns: 1fr 50px 50px 50px 1fr;
	grid-row-gap: 0px;
	grid-column-gap: 40px;

	@include media-query($mobile){
		padding:20px;
		grid-template-columns: 1fr 1fr 1fr;
		text-align:center;
	}



	div {

		@include media-query($mobile){
			&:first-of-type,
			&:last-of-type {
				display:none;

			}
		}

		a.social-icon {

			display:inline-block;
			width:50px;
			height:50px;

			@include media-query($mobile){
				// width:40px;
				// height:40px;
			}

			background-size:cover;
			background-position: center center;

			opacity:1;
			@include transition(opacity 300ms linear);

			&:hover {
				opacity:0.5;
			}

			&.social-icon-instagram {
				background-image:url(../img/icons/icon-instagram.svg);
				.no-svg & {
					background-image:url(../img/icons/icon-instagram.png);
				}
			}

			&.social-icon-linkedin {
				background-image:url(../img/icons/icon-linkedin.svg);
				.no-svg & {
					background-image:url(../img/icons/icon-linkedin.png);
				}
			}

			&.social-icon-email {
				background-image:url(../img/icons/icon-email.svg);
				.no-svg & {
					background-image:url(../img/icons/icon-email.png);
				}
			}

			span {
				@extend .visuallyhidden;
			}

		}
	}
}



#contact_slider {
	position:fixed;
	top:0;
	left:100%;
	height:100%;
	width:500px;
	min-width:50%;
	max-width:calc(100% - 20px);
	z-index:12000;

	#contact_slider_inner {
		height:100%;
		width:100%;
		@include box-sizing();
		padding:0px 20px 0px; 
		background:$color-palette-04;
		@include transform(translateX(0));
		@include transition(transform 300ms ease-in-out);
		border-left:1px solid $color-white;
	}

	&.active #contact_slider_inner {
		@include transform(translateX(-100%));
	}

	#contactScrollWrapper {
		height:100%;

		background-color:transparent;
		position:relative;
		overflow: hidden;
		/* Prevent native touch events on Windows */
		-ms-touch-action: none;

		/* Prevent the callout on tap-hold and text selection */
		-webkit-touch-callout: none;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;

		/* Prevent text resize on orientation change, useful for web-apps */
		-webkit-text-size-adjust: none;
		-moz-text-size-adjust: none;
		-ms-text-size-adjust: none;
		-o-text-size-adjust: none;
		text-size-adjust: none;
	}

	#contactScrollInner {
		position: relative;

		/* Prevent elements to be highlighted on tap */
		-webkit-tap-highlight-color: rgba(0,0,0,0);

		/* Put the scroller into the HW Compositing layer right from the start */
		-webkit-transform: translateZ(0);
		-moz-transform: translateZ(0);
		-ms-transform: translateZ(0);
		-o-transform: translateZ(0);
		transform: translateZ(0);
	}

	#contact_form_close {
		position:absolute;
		top:30px;
		left:20px;
		width:20px;
		height:20px;

		@include media-query($mobile){
			top:20px;
		}

		#contact_form_close_inner {
			@include closeButton(20px, 2px, #FFF, #FFF);
			cursor:pointer;
		}
	}


	form#contact_form {

		padding:80px 0px 20px;
		display:block;
		max-width:400px;
		margin:auto;
		color: $color-white;

		h2 {
			display:none;
		}

		.form_row { 
			position:relative;
			margin:0 0 0px 0; 
			padding:0 0 10px 0;
		}
		
		label { 
			display:none; 
			margin:0 0 5px 0;
		}

		.textinput {
			width:100%;
			border: 2px solid $color-white;
			background:$color-palette-04;
			color: $color-white;
			border-radius:5px;
			padding:6px 10px;
			@include box-sizing();
			/* Remove First */
			-webkit-appearance: none;
			-moz-appearance: none;
			appearance: none;

			&::-webkit-input-placeholder { /* Chrome/Opera/Safari */
			  color: $color-white;
			}
			&::-moz-placeholder { /* Firefox 19+ */
			  color: $color-white;
			}
			&:-ms-input-placeholder { /* IE 10+ */
			  color: $color-white;
			}
			&:-moz-placeholder { /* Firefox 18- */
			  color: $color-white;
			}

		}

		.textareainput {
			width:100%;
			border: 2px solid $color-white;
			background:$color-palette-04;
			color: $color-white;
			border-radius:5px;
			padding:6px 10px;
			@include box-sizing();
			/* Remove First */
			-webkit-appearance: none;
			-moz-appearance: none;
			appearance: none;
		}

		.submit-button {
		    padding:8px 15px 8px 15px; 
		    background:#666666; 
		    border-radius:5px; 
		    color:#FFFFFF; border:0; 
		    letter-spacing:3px;
		    margin:10px 0 0 0;
		    /* Remove First */
		    -webkit-appearance: none;
		    -moz-appearance: none;
		    appearance: none;
		}

		.form_error label { color:#F52B67; }
		.form_error label:before {content:"! ";}
		.form_error .textinput { border:1px solid #F52B67; }
		.form_error .textareainput { border:1px solid #F52B67; }

		.error_text { color:#F52B67; padding:0 0 10px 0; font-weight:bold; }

	}
}


#cookie-banner {
	position:fixed;
	bottom:0;
	left:0;
	width:100%;
	background:rgba(0,0,0,0.9);
	color:#FFF;
	z-index:2147483640;
	display:none;

	#cookie-banner-inner {
		position:relative;
		width:100%;
	}

	#cookie-banner-text {
		padding:20px 180px 20px 20px;
		text-align:left;

		@include media-query($mobile) {
			padding:20px 20px 20px 20px;
		}

		a {
			color:#FFF;
		}
	}

	#cookie-banner-button {
		position:absolute;
		right:20px;
		top:50%;
		//@include transform(translateY(-50%));
		//@include prefix('transform', translateY(-50%), moz ms webkit spec);
		@extend .button_style;
		@include transform(translateY(-50%));
		border:1px solid #FFF;
		color:#FFF;
		background:transparent;
		width:140px;
		cursor:pointer;

		@include media-query($mobile) {
			position:relative;
			right:0px;
			top:0px;
			//@include prefix('transform', translateY(0), moz ms webkit spec);
			@include transform(translateY(0));
			width:calc(100% - 40px);
			margin:0 20px 20px;

		}
	}
}




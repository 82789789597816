@import 'styles/mixins';
@import 'styles/variables';
@import 'styles/type-guide';

@import 'styles/boilerplate';
@import 'styles/structure';
@import 'styles/header';


#home_intro {
  height:100vh;
  width:100%;
  //background:$color-palette-01;

  #home_intro_inner {
    width:100%;
    height:100%;
    position:relative;

    #home_intro_content {
      position:absolute;
      top:calc(50% + 30px);
      width:100%;
      text-align:center;
      @include transform(translateY(-50%));
      font-size:3vw;
      color: $color-white;
      padding-left:20px;
      padding-right:20px;
      @include box-sizing(border-box);
      @include opacity(0);
      @include transition(top 500ms ease-in-out, opacity 750ms linear);

      @include media-query($mobile){
        font-size:28px;
      }

      &.active {
        @include opacity(1);
        top:calc(50%);
      }
    }
  }
}

#home_intro_person {
  height:100vh;
  width:100%;
  background:$color-white;
  background-image:url(img/home/chris2.jpg);
  background-size:cover;
  background-position: center center;

  #home_intro_person_inner {
    width:100%;
    height:100%;
    position:relative;

    #home_intro_person_content {
      position:absolute;
      top:calc(50%);
      width:100%;
      text-align:center;
      @include transform(translateY(-50%));
      font-size:3vw;
      color: $color-black;
      padding-left:20px;
      padding-right:20px;
      @include box-sizing(border-box);
      @include opacity(1);
      @include transition(top 500ms ease-in-out, opacity 750ms linear);
      color:$color-white;

      .title_primary {
        font-weight:bold;
        font-size:4vw;

        @include media-query($mobile){
          font-size:28px;
        }
      }

      .title_contact {
        margin-top:30px;

        @include media-query($mobile){
          margin-top:15px;
        } 

        a {
          color:$color-white;
          text-decoration:none;
          border:2px solid $color-white;
          display:inline-block;
          padding:10px 14px;
          @include transition(background 200ms ease-in-out, color 200ms linear);
          text-transform:uppercase;
          font-size:16px;
          letter-spacing:0.05em;
          border-radius:5px;

          &:hover {
            background:$color-white;
            color:$color-black;
          }
        }
      }

      @include media-query($mobile){
        font-size:20px;
      }
    }
  }
}

#homeGrid {
  display:grid;
  //grid-template-rows: minmax(100px, auto);
  grid-template-columns: 50fr 50fr;
  grid-row-gap: 1px;
  grid-column-gap: 1px;
  background:$color-white;
  

  #item_01 {
    grid-row:1;
    grid-column:2;
  }

  #item_06 {
    grid-row:1;
    grid-column:1;
  }

  #item_05 {
    grid-row:3;
    grid-column:1 / 3;

    background:$color-white;  
    min-height:20px;
      padding:0px;

  }



  div.grid_item {
    background:$color-palette-02; 
    min-height:200px;
    // border: 1px solid $color-white;
  //    border-radius: .2rem;
      padding:10px;

  }
}

#homeClients {
  background:$color-white;
  padding:20px 0;

  #homeClientsInner {
    width: 100%;
    @include clearfix();
    overflow-y:hidden;
    max-height:170px;
    @include transition(max-height 500ms linear);

    .slick-initialized {
      max-height:none;

      width: 100%;
      @include clearfix();
      overflow-y:hidden;
      max-height:170px;
      @include transition(max-height 500ms linear);

      .client {
        opacity:1;
      }
    }
  }

  .client {
    float:left;
    width:100%;
    text-align:center;
    @include box-sizing();
    opacity:0;
    @include transition(opacity 750ms linear);
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    -webkit-touch-callout: none;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      -webkit-tap-highlight-color: transparent;
  }

  .client img {
    width:calc(100% - 40px);
    height:auto;
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    -webkit-touch-callout: none;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      -webkit-tap-highlight-color: transparent;
  }
}


#home_content {
  background:$color-palette-03;
  padding:30px;

  @include media-query($mobile){
    padding:20px;
  }
}


body.page_home {

  a#logo,
  #menu_toggle {
    display:none;
  }

}



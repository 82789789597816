@import '../styles/mixins';
@import '../styles/variables';
@import '../styles/type-guide';

#mainNavigationWrapper {

	background-color:$color-palette-04;
	margin:0;
	padding:0;
	position:fixed;
	width:100%;
	max-width: 400px;
	height:100%;
	left:0px;
	@include transition(transform 200ms linear);
	@include transform(translateX(-100%) translateY(0) translateZ(0));

	z-index:10000;

	#mainNavigationInner {
		position: relative;
		width:100%;
		height:100%;
		@include box-sizing(border-box);
	}

	#mainNavigationScrollWrapper {
		height:100%;

		background-color:transparent;
		position:relative;
		overflow: hidden;
		/* Prevent native touch events on Windows */
		-ms-touch-action: none;

		/* Prevent the callout on tap-hold and text selection */
		-webkit-touch-callout: none;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;

		/* Prevent text resize on orientation change, useful for web-apps */
		-webkit-text-size-adjust: none;
		-moz-text-size-adjust: none;
		-ms-text-size-adjust: none;
		-o-text-size-adjust: none;
		text-size-adjust: none;
	}

	#mainNavigationScrollInner {
		position: relative;

		/* Prevent elements to be highlighted on tap */
		-webkit-tap-highlight-color: rgba(0,0,0,0);

		/* Put the scroller into the HW Compositing layer right from the start */
		-webkit-transform: translateZ(0);
		-moz-transform: translateZ(0);
		-ms-transform: translateZ(0);
		-o-transform: translateZ(0);
		transform: translateZ(0);
	}


	

	#menu_toggle {
		position:absolute;
		top:30px;
		width:30px;
		height:30px;
		right:calc(-30px - #{$gutter_desktop});
		
		@include media-query($small) {
			right:calc(-30px - #{$gutter_tablet});
		}
		@include media-query($mobile) {
			top:20px;
			right:calc(-30px - #{$gutter_mobile});
		}

		padding:0px 0;
		@include box-sizing(border-box);
		cursor:pointer;
		z-index:10000;
		
		@include transition(transform 200ms linear, right 200ms linear);		

		#menu_toggle_inner {
			@include burger(30px, 2px, 8px, $color-white);

			&:before,
			&:after {
				@include transition(transform background 600ms linear);
			}

			#menu_toggle_label {
				width:100%;
				height:100%;
				overflow:hidden;
				text-indent:-9999em;
			}
		}

		&.active #menu_toggle_inner {
			@include burger-to-cross($color-black);
		}

	}


	#mainNavigationLinks {

		z-index:10001;
		top:0;
		left:0;
		height:100%;
		width:100%;
		overflow-y:auto;

		&::-webkit-scrollbar { 
		    display: none; 
		}


		#mainNavigationLinksInner {
			width:100%;
			height:100%;
			min-height:450px;
			position:relative;
		}

		nav#mainNavigation {
			position:relative;
			left:0;
			width:100%;
			text-align:center;
			@include box-sizing(border-box);
			padding:50px $gutter_desktop;

			@include media-query($small) {
				padding:50px $gutter_tablet;
			}
			@include media-query($mobile) {
				padding:50px $gutter_mobile;
			}

			.menu-oversize & {
				@include transform(translateY(0%));
				top:0;
			}

		

			ul {
				margin:0;
				padding:40px 0 0;
				width:100%;
				max-width:500px;
				margin:auto;
				color:#FFF;

				li {
					margin:0;
					padding:0;
					display:block;
					text-align:left;
					font-size:25px;
					@include opacity(0);
					@include transition(opacity 300ms linear);

					&.active {
						@include opacity(1);
						@include transition(opacity 600ms linear);
					}

					a {
						color:$color-black;
						text-transform:none;
						text-decoration:none;
					}
				}
			}
		}

		
	}

}

a#logo {
	position:fixed;
	display:block;
	top:10px;
	right:20px;
	z-index:5;
	color: $color-white;
	@include transition(transform 200ms linear);
	@include transform(translateX(0) translateY(0) translateZ(0));
	text-decoration:none;

	h1 {
		font-size:20px;
		margin:0;
		padding:0;
		font-weight:normal;
	}

	h2 {
		text-align:center;
		font-size:10px;
		text-transform:uppercase;
		letter-spacing:0.35em;
		position:relative;
		left:3px;
		margin:0;
		padding:0;
		font-weight:normal;
	}
}

#menuToggleOpen {
	position:fixed;
	top:20px;
	left:20px;
	width:50px;
	height:50px;
	background:#CCC;
	overflow:hidden;

	// @include prefix('transition', background-color 0ms linear, moz ms webkit spec);
	// @include prefix('box-sizing', border-box, moz ms webkit spec);
	// @include prefix('transform', translateY(-50%), moz ms webkit spec);

	@include box-sizing();

	.burger {
		@include burger(50px, 2px, 15px, #000);
	}
}
html, body {
	background-color: #FFF;
}

html {
	overflow-x:hidden;
	width:100%;
	height:100%;
}

body {
	//height:100%;
	@extend .body_text_style;
}


body {

	@include transition(background 300ms linear);

	&.page- {
		background:$color-palette-01;
	}

	&.page-contact {
		background:#81B622;
	}

	&.page-clients {
		background:#F8D210;
	}

	&.page-skills {
		background:#FA26A0;
	}

	&.page-privacy-policy {
		background:#189AB4;
	}

	&.page-cookie-policy {
		background:#189AB4;
	}

}

#root,
.App,
.AppSection {
	//min-height:100vw;
}


#pageWrapper {
	@include transition(transform 200ms linear);
	@include transform(translateX(0) translateY(0) translateZ(0));
	width:calc(100%);
	@include box-sizing(border-box);
	padding-left:0px;
	height:100%;
	//padding:100px;
}

em {
	font-style:italic
}

#main {
	//@include opacity(0);
	@include transition(opacity 500ms linear);
	height:100%;
	.loaded & {
		@include opacity(1);
	}
}

h1, h2, h3, h4 {
	margin:0;
	padding:0 0 12px 0;
}

article.backpage {
	padding:80px $gutter_desktop 50px;
	color:#FFF;
	background:transparent !important;

	@include media-query($small) {
		padding:80px $gutter_tablet 50px;
	}

	@include media-query($mobile) {
		padding:80px $gutter_mobile 50px;
	}

	a {
		color:inherit;
	}
}


/*================ Mixins ================*/
@mixin clearfix() {
  &::after {
    content: '';
    display: table;
    clear: both;
  }

  // sass-lint:disable
  *zoom: 1;
}

/*============================================================================
  Prefix mixin for generating vendor prefixes.
  Based on https://github.com/thoughtbot/bourbon/blob/v4-stable/app/assets/stylesheets/addons/_prefixer.scss

  Usage:
    // Input:
    .element {
      @include prefix(transform, scale(1), ms webkit spec);
    }

    // Output:
    .element {
      -ms-transform: scale(1);
      -webkit-transform: scale(1);
      transform: scale(1);
    }
==============================================================================*/
@mixin prefix($property, $value, $prefixes) {
  @each $prefix in $prefixes {
    @if $prefix == webkit {
      -webkit-#{$property}: $value;
    } @else if $prefix == moz {
      -moz-#{$property}: $value;
    } @else if $prefix == ms {
      -ms-#{$property}: $value;
    } @else if $prefix == o {
      -o-#{$property}: $value;
    } @else if $prefix == spec {
      #{$property}: $value;
    } @else  {
      @warn 'Unrecognized prefix: #{$prefix}';
    }
  }
}

/*================ Media Query Mixin ================*/
@mixin media-query($media-query) {
  $breakpoint-found: false;

  @each $breakpoint in $breakpoints {
    $name: nth($breakpoint, 1);
    $declaration: nth($breakpoint, 2);

    @if $media-query == $name and $declaration {
      $breakpoint-found: true;

      @media only screen and #{$declaration} {
        @content;
      }
    }
  }

  @if $breakpoint-found == false {
    @warn 'Breakpoint "#{$media-query}" does not exist';
  }
}

/*================ Responsive Show/Hide Helper ================*/
@mixin responsive-display-helper($breakpoint: '') {
  // sass-lint:disable no-important
  .#{$breakpoint}show {
    display: block !important;
  }

  .#{$breakpoint}hide {
    display: none !important;
  }
}


/*================ Responsive Text Alignment Helper ================*/
@mixin responsive-text-align-helper($breakpoint: '') {
  // sass-lint:disable no-important
  .#{$breakpoint}text-left {
    text-align: left !important;
  }

  .#{$breakpoint}text-right {
    text-align: right !important;
  }

  .#{$breakpoint}text-center {
    text-align: center !important;
  }
}

@mixin visually-hidden() {
  // sass-lint:disable no-important
  position: absolute !important;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
}

@mixin visually-shown($position: inherit) {
  // sass-lint:disable no-important
  position: $position !important;
  overflow: auto;
  clip: auto;
  width: auto;
  height: auto;
  margin: 0;
}


@mixin box-sizing($box-sizing: border-box) {
  @include prefix('box-sizing', $box-sizing, moz ms webkit spec);
}

@mixin transform($transform) {
  @include prefix('transform', $transform, moz ms webkit spec);
}

@mixin opacity($opacity) {
    @if $opacity == 1 {
      filter: unquote("progid:DXImageTransform.Microsoft.Alpha(enabled=false)");
    }
    @else {
      filter: unquote("progid:DXImageTransform.Microsoft.Alpha(Opacity=#{round($opacity * 100)})");
    }
  
 
  opacity: $opacity;
}



// Shorthand mixin. Supports multiple parentheses-deliminated values for each variable.
// Example: @include transition (all 2s ease-in-out);
//          @include transition (opacity 1s ease-in 2s, width 2s ease-out);
//          @include transition-property (transform, opacity);

@mixin transition ($properties...) {
  // Fix for vendor-prefix transform property
  $needs-prefixes: false;
  $webkit: ();
  $moz: ();
  $spec: ();

  // Create lists for vendor-prefixed transform
  @each $list in $properties {
    @if nth($list, 1) == "transform" {
      $needs-prefixes: true;
      $list1: -webkit-transform;
      $list2: -moz-transform;
      $list3: ();

      @each $var in $list {
        $list3: join($list3, $var);

        @if $var != "transform" {
          $list1: join($list1, $var);
          $list2: join($list2, $var);
        }
      }

      $webkit: append($webkit, $list1);
         $moz: append($moz,    $list2);
        $spec: append($spec,   $list3);
    }

    // Create lists for non-prefixed transition properties
    @else {
      $webkit:  append($webkit, $list, comma);
      $moz:     append($moz,    $list, comma);
      $spec:    append($spec,   $list, comma);
    }
  }

  @if $needs-prefixes {
    -webkit-transition: $webkit;
       -moz-transition: $moz;
            transition: $spec;
  }
  @else {
    @if length($properties) >= 1 {
      @include prefix(transition, $properties, webkit moz spec);
    }

    @else {
      $properties: all 0.15s ease-out 0s;
      @include prefix(transition, $properties, webkit moz spec);
    }
  }
}

@mixin transition-property ($properties...) {
   -webkit-transition-property: transition-property-names($properties, 'webkit');
      -moz-transition-property: transition-property-names($properties, 'moz');
           transition-property: transition-property-names($properties, false);
}

@mixin transition-duration ($times...) {
  @include prefix(transition-duration, $times, webkit moz spec);
}

@mixin transition-timing-function ($motions...) {
// ease | linear | ease-in | ease-out | ease-in-out | cubic-bezier()
  @include prefix(transition-timing-function, $motions, webkit moz spec);
}

@mixin transition-delay ($times...) {
  @include prefix(transition-delay, $times, webkit moz spec);
}






/*================ BURGER MIXIN ================*/


$sass-burger-add-vendor-prefixes: true !default;

// Burger
@mixin burger($width: 30px, $height: 5px, $gutter: 3px, $color: #000, $border-radius: 0, $transition-duration: .3s) {
    $burger-height: $height !global;
    $burger-gutter: $gutter !global;

    position: relative;
    margin-top: $height + $gutter;
    margin-bottom: $height + $gutter;

    @if $sass-burger-add-vendor-prefixes {
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
    }
    user-select: none;

    // 1. Fixes jagged edges in Firefox, see issue #10.
    &, &::before, &::after {
        display: block;
        width: $width;
        height: $height;
        background-color: $color;
        outline: 1px solid transparent; // 1
        @if $border-radius != 0 {
            border-radius: $border-radius;
        }

        @if $sass-burger-add-vendor-prefixes {
            -webkit-transition-property: background-color, -webkit-transform;
            -moz-transition-property: background-color, -moz-transform;
            -o-transition-property: background-color, -o-transform;
        }
        transition-property: background-color, transform;

        @if $sass-burger-add-vendor-prefixes {
            -webkit-transition-duration: $transition-duration;
            -moz-transition-duration: $transition-duration;
            -o-transition-duration: $transition-duration;
        }
        transition-duration: $transition-duration;
    }

    &::before, &::after {
        position: absolute;
        content: "";
    }

    &::before {
        top: -($height + $gutter);
    }

    &::after {
        top: $height + $gutter;
    }
}


// Select parts of the burger
@mixin burger-parts {
    &, &::before, &::after {
        @content;
    }
}

@mixin burger-top {
    &::before {
        @content;
    }
}

@mixin burger-middle {
    & {
        @content;
    }
}

@mixin burger-bottom {
    &::after {
        @content;
    }
}


// Burger animations
@mixin burger-to-cross($color: auto) {
    & {
        background-color: transparent;
    }
    @if ($color != auto) {
        &::before, &::after {
            background-color: $color;
        }
    }
    &::before {
        @include transform(translateY($burger-gutter + $burger-height) rotate(45deg));
    }
    &::after {
        @include transform(translateY(-($burger-gutter + $burger-height)) rotate(-45deg));
    }
}




@mixin burger-to-arrow($color: auto) {
    & {
        background-color: transparent;
    }
    @if ($color != auto) {
        &::before, &::after {
            background-color: $color;
        }
    }
    &::before {
        @include transform(translateY($burger-gutter + $burger-height) translateX(-4px) rotate(45deg));
    }
    &::after {
        @include transform(translateY(-($burger-gutter + $burger-height)) translateX(4px) rotate(-45deg));
        left:8px;
    }
}

@mixin closeButton ($size, $thickness, $color, $colorHover) {
  & {
    display: inline-block;
    width: $size;
    height: $size;
  }

  &:before, &:after {
    content: '';
    display: block;
    width: 140%;
    height: $thickness;
    background: $color;
    }

  &:before {
    transform: rotate(45deg);
    transform-origin: 0 0;
    margin-top: -$thickness/4;
    margin-left: $thickness/2;
  }

  &:after {
    transform: rotate(-45deg);
    transform-origin: 100% 0;
    margin-left: -41%;
    margin-top: -$thickness;
  }

  &:hover {
    cursor: pointer;

    &:before, &:after {
      background: $colorHover;
    } 
  }
}

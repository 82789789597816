

.body_text_style {
	font-family: $base-font-family;
}



.button_style {
	display: inline-block;
	text-transform: uppercase;
	font-size:12px;
	line-height: 26px;
	background-color:$color-black;
	color:$color-white;
	font-weight:normal;
	text-decoration:none;
	border:0;
	padding:8px 10px 6px;
	width:300px;
	max-width:100%;
	border-radius:0;
	-webkit-appearance: none;
	//border:1px solid $color-black;
	text-align:center;
	@include box-sizing();
}


// PAGE GUTTERING
$gutter_desktop: 30px;
$gutter_tablet: 30px;
$gutter_mobile: 20px;



/*================ Color Variables ================*/
$color-palette-01: #6534ff;
$color-palette-02: #62bcfa;
$color-palette-03: #fccdd3;
$color-palette-04: #bbc4ef;

$color-white: #FFF;
$color-black: #000;

/*================ font families ================*/
$base-font-family: 'DomainSansLight', 'Playfair Display', 'Arial', 'Helvetica', 'Arial', sans-serif;
$italic-font-family: 'DomainSansLight', 'Playfair Display', 'Arial', 'Helvetica', 'Arial', sans-serif;


/*============================================================================
  Grid Breakpoints and Class Names
    - Do not change the variable names
==============================================================================*/
$grid-mobile: 641px;
$grid-medium: 769px;
$grid-large: 990px;
$grid-widescreen: 1400px;
$grid-gutter: 30px;
$nav-break: 980px;
$font-break-medium: 900px;
$font-break-small: 600px;

$mobile: 'mobile';
$small: 'small';
$small-down: 'small-down';
$small-up: 'small-up';
$medium: 'medium';
$medium-down: 'medium-down';
$medium-up: 'medium-up';
$large: 'large';
$large-down: 'large-down';
$large-up: 'large-up';
$widescreen: 'widescreen';
$below-nav-break: 'below-nav-break';
$above-nav-break: 'above-nav-break';
$font-medium-down: 'font-medium-down';
$font-medium-up: 'font-medium-up';
$font-medium: 'font-medium';
$font-small: 'font-small';

// The `$breakpoints` list is used to build our media queries.
// You can use these in the media-query mixin.
$breakpoints: (
  $mobile '(max-width: #{$grid-mobile - 1})',
  $small '(min-width: #{$grid-mobile}) and (max-width: #{$grid-medium - 1})',
  $small-down '(max-width: #{$grid-medium - 1})',
  $small-up '(min-width: #{$grid-mobile})',
  $medium '(min-width: #{$grid-medium}) and (max-width: #{$grid-large - 1})',
  $medium-down '(max-width: #{$grid-large - 1})',
  $medium-up '(min-width: #{$grid-medium})',
  $large '(min-width: #{$grid-large}) and (max-width: #{$grid-widescreen - 1})',
  $large-down '(max-width: #{$grid-widescreen - 1})',
  $large-up '(min-width: #{$grid-large})',
  $widescreen '(min-width: #{$grid-widescreen})',
  $above-nav-break '(min-width: #{$nav-break})',
  $below-nav-break '(max-width: #{$nav-break - 1})',
  $font-medium-down '(max-width: #{$font-break-medium - 1})',
  $font-medium-up '(min-width: #{$font-break-medium})',
  $font-medium '(min-width: #{$font-break-small}) and (max-width: #{$font-break-medium - 1})',
  $font-small '(max-width: #{$font-break-small - 1})'
);


@font-face {
  font-family: 'DomainSansRegular';
  src: url('../fonts/DomaineSansTextWeb-Regular.eot');
  src: url('../fonts/DomaineSansTextWeb-Regular.eot?#iefix') format('embedded-opentype'),
  url('../fonts/DomaineSansTextWeb-Regular.woff2') format('woff2'),
  url('../fonts/DomaineSansTextWeb-Regular.woff') format('woff');
}

@font-face {
  font-family: 'DomainSansLight';
  src: url('../fonts/DomaineSansTextWeb-Light.eot');
  src: url('../fonts/DomaineSansTextWeb-Light.eot?#iefix') format('embedded-opentype'),
  url('../fonts/DomaineSansTextWeb-Light.woff2') format('woff2'),
  url('../fonts/DomaineSansTextWeb-Light.woff') format('woff');
}


/*
 * Hide only visually, but have it available for screen readers:
 * https://snook.ca/archives/html_and_css/hiding-content-for-accessibility
 *
 * 1. For long content, line feeds are not interpreted as spaces and small width
 *    causes content to wrap 1 word per line:
 *    https://medium.com/@jessebeach/beware-smushed-off-screen-accessible-text-5952a4c2cbfe
 */

.visuallyhidden {
    border: 0;
    clip: rect(0 0 0 0);
    -webkit-clip-path: inset(50%);
    clip-path: inset(50%);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
    white-space: nowrap; /* 1 */
}

/*
 * Extends the .visuallyhidden class to allow the element
 * to be focusable when navigated to via the keyboard:
 * https://www.drupal.org/node/897638
 */

.visuallyhidden.focusable:active,
.visuallyhidden.focusable:focus {
    clip: auto;
    -webkit-clip-path: none;
    clip-path: none;
    height: auto;
    margin: 0;
    overflow: visible;
    position: static;
    width: auto;
    white-space: inherit;
}
